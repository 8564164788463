import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Alert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import some from 'lodash/some';
import every from 'lodash/every';
import PropTypes from 'prop-types';

import {
  createBoxingLineEscalation,
  postBoxingLineEscalationPhoto,
} from 'redux/boxingLineEscalation/actions';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const BoxingLineEscalationForm = ({ session }) => {
  const dispatch = useDispatch();

  const [errorMessage, setErrorMessage] = useState('');
  const [wasSubmitted, setWasSubmitted] = useState(false);
  const defaultForm = {
    packingFacilityId: session.packing_facility_id,
    issue: '',
    lines: [],
    zones: [],
    optionalZones: '',
    printerIPAddress: '',
    name: '',
    email: '',
    phoneNumber: '',
    imageUrls: [],
    fileNames: [],
  };
  const [form, setForm] = useState(defaultForm);
  const lineNames = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];
  const [linesChecked, setLinesChecked] = useState(() =>
    lineNames.map(() => false)
  );
  const zoneIds = Array.from({ length: 23 }, (_, num) => ++num);
  const [zonesChecked, setZonesChecked] = useState(() =>
    zoneIds.map(() => false)
  );

  const handleLineChangeAll = (event) => {
    setLinesChecked(lineNames.map(() => event.target.checked));
  };

  const handleZoneChangeAll = (event) => {
    setZonesChecked(zonesChecked.map(() => event.target.checked));
  };

  const displayZones = (start, end) =>
    zoneIds.slice(start, end).map((zoneId, position) => (
      <FormControlLabel
        sx={{ ml: 2 }}
        key={zoneId}
        label={`Zone ${zoneId}`}
        control={
          <Checkbox
            checked={zonesChecked[start + position]}
            onChange={(event) => {
              zonesChecked[start + position] = event.target.checked;
              setZonesChecked([...zonesChecked]);
            }}
          />
        }
      />
    ));

  const isValid = (boxingLineEscalationForm) => {
    if (
      boxingLineEscalationForm.name === '' ||
      boxingLineEscalationForm.phoneNumber === '' ||
      boxingLineEscalationForm.email === ''
    ) {
      setErrorMessage('There was an error submitting.');
      return false;
    } else {
      setErrorMessage('');
      return true;
    }
  };

  const lines = (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1 }}>
      <FormControlLabel
        label="Boxing Lines"
        control={
          <Checkbox
            checked={every(linesChecked)}
            indeterminate={!every(linesChecked) && some(linesChecked)}
            onChange={handleLineChangeAll}
          />
        }
      />
      {lineNames.map((name, position) => (
        <FormControlLabel
          sx={{ ml: 2 }}
          key={name}
          label={`Line ${name}`}
          control={
            <Checkbox
              checked={linesChecked[position]}
              onChange={(event) => {
                linesChecked[position] = event.target.checked;
                setLinesChecked([...linesChecked]);
              }}
            />
          }
        />
      ))}
    </Box>
  );

  const zones = (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1 }}>
        <FormControlLabel
          label="Zones"
          control={
            <Checkbox
              checked={every(zonesChecked)}
              indeterminate={!every(zonesChecked) && some(zonesChecked)}
              onChange={handleZoneChangeAll}
            />
          }
        />
        {displayZones(0, 9)}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1, pt: 5 }}>
        {displayZones(9, 18)}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1, pt: 5 }}>
        {displayZones(18, 24)}
        <TextField
          id="outlined-required"
          label="Optional Zones"
          value={form.optionalZones}
          onChange={(event) =>
            setForm({
              ...form,
              optionalZones: event.currentTarget.value,
            })
          }
        />
      </Box>
    </>
  );

  return (
    <>
      <h1>Boxing Line Escalation Form</h1>
      {wasSubmitted && <Alert severity="success">Request was submitted.</Alert>}
      {errorMessage !== '' && <Alert severity="error">{errorMessage}</Alert>}
      <Box
        component="form"
        sx={{ '& .MuiTextField-root': { m: 1 } }}
        noValidate
        autoComplete="off"
      >
        <FormGroup>
          <TextField
            id="outlined-required"
            label="Description of the Issue"
            value={form.issue}
            onChange={(event) =>
              setForm({
                ...form,
                issue: event.currentTarget.value,
              })
            }
          />
        </FormGroup>
        <Stack direction="row" spacing={2} sx={{ ml: 1 }}>
          {lines}
          {zones}
        </Stack>
        <FormGroup>
          <TextField
            id="outlined-required"
            label="Printer IP Address"
            value={form.printerIPAddress}
            onChange={(event) =>
              setForm({
                ...form,
                printerIPAddress: event.currentTarget.value,
              })
            }
          />
        </FormGroup>
        <Button
          sx={{ ml: 1 }}
          component="label"
          role={undefined}
          variant="outlined"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
        >
          Upload files
          <VisuallyHiddenInput
            type="file"
            onChange={async (event) => {
              const files = event.target.files;
              const formData = new FormData();

              const fileNames = [];
              for (const file of files) {
                formData.append('uploaded_images[]', file, file.name);
                fileNames.push(file.name);
              }

              setForm({
                ...form,
                fileNames: fileNames,
              });

              const response = await dispatch(
                postBoxingLineEscalationPhoto(formData)
              );

              if (response.ok) {
                setErrorMessage('');
                setForm({
                  ...form,
                  imageUrls: response.image_urls,
                  fileNames: fileNames,
                });
              } else {
                setErrorMessage('There was an issue uploading your files.');
              }
            }}
            multiple
          />
        </Button>
        <ul style={{ margin: '8px' }}>
          {form.fileNames.map((fileName) => (
            <li key={fileName}>{fileName}</li>
          ))}
        </ul>
        <FormGroup sx={{ ml: 1 }}>
          <InputLabel>
            Person on site that will actively troubleshoot
          </InputLabel>
          <TextField
            data-testid="name-input"
            id="outlined-required"
            label="Name"
            required
            value={form.name}
            error={errorMessage !== '' && form.name === ''}
            onChange={(event) =>
              setForm({
                ...form,
                name: event.currentTarget.value,
              })
            }
          />
          <TextField
            data-testid="email-input"
            id="outlined-required"
            label="Email"
            required
            value={form.email}
            error={errorMessage !== '' && form.email === ''}
            onChange={(event) =>
              setForm({
                ...form,
                email: event.currentTarget.value,
              })
            }
          />
          <TextField
            data-testid="phone-number-input"
            id="outlined-required"
            label="Phone Number"
            required
            value={form.phoneNumber}
            error={errorMessage !== '' && form.phoneNumber === ''}
            onChange={(event) => {
              setForm({
                ...form,
                phoneNumber: event.currentTarget.value,
              });
            }}
          />
        </FormGroup>
        <Button
          sx={{ mx: 1 }}
          variant="contained"
          onClick={async () => {
            const selectCheckedZoneIds = [];
            zonesChecked.map((zone, index) => {
              if (zone) {
                selectCheckedZoneIds.push(index + 1);
              }
            });

            const selectCheckedLines = [];
            linesChecked.map((line, index) => {
              if (line) {
                selectCheckedLines.push(lineNames[index]);
              }
            });

            const boxingLineEscalationForm = {
              ...form,
              zones: selectCheckedZoneIds,
              lines: selectCheckedLines,
            };

            setForm({ ...boxingLineEscalationForm });
            if (isValid(boxingLineEscalationForm)) {
              const response = await dispatch(
                createBoxingLineEscalation({
                  boxingLineEscalationForm,
                })
              );

              if (response.ok) {
                setWasSubmitted(true);
                setForm(defaultForm);
                setLinesChecked(lineNames.map(() => false));
                setZonesChecked(zoneIds.map(() => false));
              } else {
                setErrorMessage('There was an error submitting.');
                setWasSubmitted(false);
              }
            } else {
              setWasSubmitted(false);
            }
          }}
        >
          Submit
        </Button>
      </Box>
    </>
  );
};

BoxingLineEscalationForm.propTypes = {
  session: PropTypes.object,
};

BoxingLineEscalationForm.defaultProps = {
  session: undefined,
};

export default BoxingLineEscalationForm;
